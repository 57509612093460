<template>
  <div>
    <div class="d-flex justify-content-between mb-4">
      <h1 class="h3 ml-2">
        <div class="date-picker">
          <input v-model="selectedDate" class="picker" type="date" />
        </div>
        <span v-if="period"> {{ period.isoYear }} Week {{ period.isoWeek }} </span>
        <small v-if="period">({{ period.startDate.format('DD.MM.') }} - {{ period.endDate.format('DD.MM.') }})</small>
      </h1>
      <DotMenu>
        <li v-if="orderKey === 'deliveryDate'" @click="orderKey = 'createdAt'">By Order Date</li>
        <li v-if="orderKey === 'createdAt'" @click="orderKey = 'deliveryDate'">By Delivery Date</li>
        <li @click="copyOrderIdsArray">Copy Order IDs</li>
      </DotMenu>
    </div>
    <OrdersList v-if="Array.isArray(orders)" :orders="orders" />
    <TableSkeleton v-else />
  </div>
</template>

<script>
import moment from 'moment'
require('moment/locale/de')
import { db } from '@/shared/firebase'

import DotMenu from '@/shared/DotMenu'
import OrdersList from '@/components/OrdersList'
import TableSkeleton from '@/components/TableSkeleton'

export default {
  components: { OrdersList, DotMenu, TableSkeleton },
  data() {
    return {
      selectedDate: null,
      orderKey: 'createdAt',
      ordersSubscription: null,
      orders: []
    }
  },
  computed: {
    period() {
      if (!this.selectedDate) return null
      const startDate = moment(this.selectedDate).startOf('isoWeek')
      const endDate = startDate.clone().endOf('isoWeek')
      return { startDate, endDate, isoWeek: startDate.isoWeek(), isoYear: startDate.isoWeekYear() }
    },
    weekPeriod() {
      const { weekString } = this
      if (!this.weekString) return null
      const date = moment()
        .year(weekString.substr(0, 4))
        .isoWeek(weekString.substr(5, 2))
      return `${date.startOf('isoWeek').format('DD.MM.')} - ${date.endOf('isoWeek').format('DD.MM.')}`
    }
  },
  created() {
    this.selectedDate = moment().format('YYYY-MM-DD')
  },
  watch: {
    orderKey() {
      const { startDate, endDate } = this.period
      this.subscribe(startDate.toDate(), endDate.toDate())
    },
    selectedDate() {
      if (this.period) {
        const { startDate, endDate } = this.period
        this.subscribe(startDate.toDate(), endDate.toDate())
      } else {
        this.orders = null
        this.ordersSubscription()
      }
    }
  },
  methods: {
    subscribe(from, to) {
      this.orders = null
      if (this.ordersSubscription) this.ordersSubscription()
      this.ordersSubscription = db
        .collection('orders')
        .where(this.orderKey, '>=', from)
        .where(this.orderKey, '<=', to)
        .orderBy(this.orderKey, 'desc')
        .onSnapshot(snapshot => {
          this.orders = snapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
          })
        })
    },
    copyOrderIdsArray() {
      const orderIds = []
      Object.keys(this.ordersByWeek).forEach(weekKey => {
        this.ordersByWeek[weekKey].forEach(order => {
          orderIds.push(order.id)
        })
      })
      this.copyToClipboard(JSON.stringify(orderIds))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.orders {
  &__send-mail-button {
    @extend %cleanbutton;

    width: 2rem;
    height: 2rem;

    background: url(../assets/icons/send-email.svg) center center no-repeat;
    background-size: 1.5rem;

    text-indent: -9999em;
  }
}

.date-picker {
  position: relative;
  width: 2rem;
  height: 2rem;
  display: inline-block;

  input[type='date'] {
    border: none;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    text-indent: -9999em;
    background: transparent;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: transparent url(../assets/icons/calendar.svg) center center no-repeat;
    background-size: 1.5rem;
    &:focus {
      outline: none;
    }
  }
}
</style>
